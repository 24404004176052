<template>
    <div class="box">
        <h2>我的收藏夹</h2>
        <div class="table">
            <el-table :data="goodsList" @selection-change="setCheck" :header-cell-style="{ background: 'rgb(250,250,250)', color: '#333333' }" class="top1">
                <el-table-column type="selection" width="50px"> </el-table-column>
                <el-table-column label="商品图片" align="center">
                    <template slot-scope="scope">
                      <a :href="'/sku-'+scope.row.goods_id">
                        <img :src="$imgUrlTo(scope.row.goods_image)" min-width="70" height="70" />
                      </a>
                    </template>
                </el-table-column>
                <el-table-column prop="goods_name" label="商品名称" align="center">
                    <template slot-scope="scope">
                      <a :href="'/sku-'+scope.row.goods_id">
                        {{ scope.row.goods_name }}
                      </a>
                    </template>
                </el-table-column>
                <el-table-column prop="sku_no" label="国际条码" align="center" width="130"> </el-table-column>
                <el-table-column prop="goods_no" label="商品货号" align="center"> </el-table-column>
                <el-table-column prop="color_name" label="商品颜色" align="center"> </el-table-column>
                <el-table-column prop="brand_name" label="品牌" align="center"> </el-table-column>
                <el-table-column prop="total" label="整箱数量" align="center"> </el-table-column>
                <el-table-column prop="goods_stock" label="库存" align="center"> </el-table-column>
                <el-table-column prop="price" label="您的价格" align="center"> </el-table-column>
                <el-table-column label="收藏时间" align="center">
                    <template slot-scope="scope">
                        {{ $util.timeStampTurnTime(scope.row.create_time) }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku_image" label="操作" align="center">
                    <template slot-scope="scope">
                        <span class="delete" @click="deleteGoods(scope.row.goods_id)">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination :page-size="goodsInfo.page_size" @current-change="getGoodsCollect" :current-page.sync="goodsInfo.page" layout="prev, pager, next, jumper" :total="goodsTotal"> </el-pagination>
        <div class="controller">
            <div class="controller-left">
                <el-button class="control-btn delete" :disabled="!checkGoods.length" @click="deleteGoodsChecked()">删除</el-button>
            </div>
            <div class="controller-right">
                <el-button class="submit-btn red" @click="addCart">加入购物车</el-button>
            </div>
        </div>
    </div>
</template>


<script>
import { goodsCollect, deleteGoods } from "@/api/member/collection"
import { mapGetters } from "vuex"
import { goodsRecommend } from "@/api/goods/goods"
export default {
    name: "collection",
    components: {},
    data() {
        return {
            goodsInfo: {
                page: 1,
                page_size: 10
            },
            shopInfo: {
                page: 1,
                page_size: 10
            },
            activeName: "goods",
            goodsTotal: 0,
            goodsList: [],
            loading: true,
            yes: true,
            list: [],
            page: 1,
            pageSize: 5,
            checkGoods: []
        }
    },
    created() {
        this.getGoodsCollect()
    },
    computed: {
        ...mapGetters(["defaultGoodsImage"])
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        addCart() {
            const joinCart = (goods) => {
                this.$store
                    .dispatch("cart/add_to_cart", {
                        // site_id: skuGoods.site_id,
                        sku_id: goods.sku_id,
                        num: 1
                    })
                    .then((res) => {
                        var data = res.data
                        if (data > 0) {
                            this.$message({
                                message: "加入购物车成功",
                                type: "success"
                            })
                        }
                        // this.btnSwitch = false
                    })
                    .catch((err) => {
                        if (err.message == "您尚未登录，请先进行登录") {
                            this.$router.push("/login")
                        } else {
                            this.$message.error(err.message)
                        }
                        // this.btnSwitch = false
                    })
            }
            this.checkGoods.forEach((item) => {
                joinCart(item)
            })
        },
        deleteGoodsChecked() {
            this.checkGoods.forEach((item) => {
                deleteGoods({ goods_id: item.goods_id })
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({ message: "取消关注成功", type: "success" })
                            this.getGoodsCollect()
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err.message)
                    })
            })
        },
        setCheck(e) {
            this.checkGoods = e
        },
        //获取关注商品
        getGoodsCollect() {
            goodsCollect(this.goodsInfo)
                .then((res) => {
                    console.log(res, "getGoodsCollect")
                    this.goodsTotal = res.data.count
                    this.goodsList = res.data.list
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        //删除关注商品
        deleteGoods(id) {
            deleteGoods({ goods_id: id })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message({ message: "取消关注成功", type: "success" })
                        this.getGoodsCollect()
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },
        imageError(index) {
            this.goodsList[index].sku_image = this.defaultGoodsImage
        },
        imageImgError(index) {
            this.list[index].sku_image = this.defaultGoodsImage
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .switchTab {
            display: flex;
            align-items: center;

            .tab-item {
                font-size: $ns-font-size-lg;
                font-weight: 400;
                padding: 0 15px;
                border-right: 1px solid #e1e1e1;
                height: 20px;
                display: block;
                line-height: 20px;
                cursor: pointer;

                &:last-child {
                    border: 0;
                }
            }
        }
    }

    .form-row {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .form-item {
            display: flex;
            align-items: center;
            color: $ns-text-color-black;

            span {
                margin-right: 10px;
            }

            .search-btn {
                height: 32px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                margin-left: 10px;

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }

    .table {
        width: 100%;
        margin-top: 30px;
    }

    .el-pagination {
        margin-top: 30px;
    }

    .delete {
        color: $base-color;
        cursor: pointer;
    }

    .controller {
        display: flex;
        margin-top: 30px;
        align-items: center;

        .controller-left {
            width: 100%;
            height: 40px;
            border: 1px solid #d9d9d9;
            background-color: $base-color-gray;
            display: flex;
            align-items: center;
            position: relative;
            box-sizing: border-box;
            padding-left: 20px;

            .control-btn {
                height: 32px;
                padding: 0 20px;
                border-radius: 14px;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                // margin-left: 20px;
                border: none;

                &.delete {
                    // color: $ns-text-color-black;
                    // background-color: transparent;
                }
            }

            .price {
                position: absolute;
                right: 20px;
                top: 0;
                line-height: 40px;
                font-size: $ns-font-size-base;
                color: $ns-text-color-black;

                span {
                    color: red;
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0 3px;
                }
            }
        }

        .controller-right {
            width: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .el-button {
                padding: 0;
                width: 130px;
                height: 40px;
                border: 0;
                display: flex;
                align-items: center;
                color: #ffffff;
                justify-content: center;
                font-size: $ns-font-size-lg;
                background-color: $base-color;
                border-radius: 0;
                opacity: 0.7;

                &.green {
                    opacity: 1;
                    background-color: #2d9842;
                }

                &.red {
                    opacity: 1;
                }
            }
        }
    }
}
.top1 {
    width: 1240px !important;
}
</style>
